import Head from 'next/head';
import Image from 'next/image';
import { NextPageWithLayout } from 'pages/_app';
import { useIntl } from 'react-intl';

const GetStarted: NextPageWithLayout = () => {
  const intl = useIntl();

  // const router = useRouter();

  // useEffect(() => {
  //   if (router.pathname === '/') {
  //     router.push('/user/sign-up');
  //   }
  // }, [router.pathname]);

  return (
    <>
      <Head>
        <title>{intl.formatMessage({ id: 'page.home.head.title' })}</title>
        <meta
          name="description"
          content={intl.formatMessage({ id: 'page.home.head.description' })}
        />
      </Head>
      <div className="h-full container-default">
        <div className="flex items-center justify-center h-full">
          <Image
            src="https://mhp-icons.s3.amazonaws.com/mint-lockup.png"
            alt="Logo"
            width={250}
            height={120}
            objectFit="contain"
          />
        </div>
      </div>
    </>
  );
};

export default GetStarted;
